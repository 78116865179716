<template>
  <v-img
    :src="src"
    width="150"
    class="mr-auto"
    contain
    :style="{ 'background-color': backgroundColor }"
    :aspect-ratio="7 / 3"
  ></v-img>
</template>

<script>
export default {
  mixins: [],

  components: {},

  props: {
    src: String,
    backgroundColor: String,
  },

  data() {
    return {};
  },

  methods: {},

  computed: {},
};
</script>
