<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card :disabled="loading">
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-text-field v-model="color" filled autofocus></v-text-field>

        <v-select
          v-if="!hideDarkInput"
          v-model="dark"
          label="Tema"
          filled
          :items="themeItems"
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="submit" color="primary" block :loading="loading">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogsMixin from "@/mixins/DialogsMixin.vue";

export default {
  mixins: [DialogsMixin],

  components: {},

  props: {
    title: String,
    hideDarkInput: Boolean,
  },

  data() {
    return {
      color: null,
      dark: true,
      themeItems: [
        {
          text: "Claro",
          value: false,
        },
        {
          text: "Escuro",
          value: true,
        },
      ],
    };
  },

  methods: {
    open(color) {
      this.color = color;
      this.dialog = true;
    },
    submit() {
      this.$emit("submit", {
        color: this.color,
        dark: this.hideDarkInput ? undefined : this.dark,
      });
    },
  },

  computed: {},
};
</script>
