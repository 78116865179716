<script>
export default {
  mixins: [],

  components: {},

  props: {},

  data() {
    return {
      dialog: false,
      loading: false,
    };
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },

  computed: {},
};
</script>
