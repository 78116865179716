<template>
  <div>
    <v-row>
      <v-col>
        <v-simple-table>
          <tbody>
            <tr>
              <th colspan="2">Sistema</th>
            </tr>
            <tr @click="$refs.main_logo_uploader.open(insurer.main_logo)">
              <td width="150">Logo</td>
              <td>
                <Logo :src="insurer.main_logo" />
              </td>
            </tr>
            <tr @click="$refs.primary_color_picker.open(insurer.primary_color)">
              <td>Cor primária</td>
              <td>
                <div
                  class="color-rectangle"
                  :style="{ 'background-color': insurer.primary_color }"
                ></div>
              </td>
            </tr>
            <tr>
              <th colspan="2">Barra superior</th>
            </tr>
            <tr @click="$refs.header_logo_uploader.open(insurer.header_logo)">
              <td>Logo superior</td>
              <td width="150">
                <Logo
                  :src="insurer.header_logo"
                  :background-color="insurer.header_color"
                />
              </td>
            </tr>
            <tr @click="$refs.header_color_picker.open(insurer.header_color)">
              <td>Cor</td>
              <td>
                <div
                  class="color-rectangle"
                  :style="{ 'background-color': insurer.header_color }"
                ></div>
              </td>
            </tr>
            <tr @click="$refs.header_color_picker.open(insurer.header_color)">
              <td>Tema</td>
              <td class="text-left">
                {{ insurer.dark_header ? "Escuro" : "Claro" }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <LogoUploader ref="main_logo_uploader" @submit="handleUploadMainLogo" />
    <LogoUploader
      ref="header_logo_uploader"
      :background-color="insurer.header_color"
      @submit="handleUploadHeaderLogo"
    />
    <ColorPickerDialog
      ref="primary_color_picker"
      title="Cor primária"
      hide-dark-input
      @submit="handleUpdatePrimaryColor"
    />
    <ColorPickerDialog
      ref="header_color_picker"
      title="Cor da barra superior"
      @submit="handleUpdateHeaderColor"
    />
  </div>
</template>

<script>
import Logo from "@/components/InsurersPortal/Logo.vue";
import {
  show,
  updateLogo,
  updateHeaderLogo,
  updateTheme,
  updateHeader,
} from "@/services/configs/insurers_portal";
import LogoUploader from "@/components/InsurersPortal/LogoUploader.vue";
import ToastsMixin from "@/mixins/ToastsMixin.vue";
import ColorPickerDialog from "@/components/colors/ColorPickerDialog.vue";

export default {
  mixins: [ToastsMixin],

  components: {
    Logo,
    LogoUploader,
    ColorPickerDialog,
  },

  props: {
    id: String,
    insurer: Object,
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async loadInsurer() {
      this.insurer = await show(this.id);
    },

    async handleUploadMainLogo(logo) {
      if (!logo) {
        this.$refs.main_logo_uploader.close();
        return;
      }

      this.$refs.main_logo_uploader.setLoading(true);
      try {
        this.insurer = await updateLogo(this.id, logo);
        this.$refs.main_logo_uploader.close();
        this.toast("Logo atualizado");
      } catch (error) {
        this.toastError("Não foi possível atualizar o logo");
      } finally {
        this.$refs.main_logo_uploader.setLoading(false);
      }
    },

    async handleUploadHeaderLogo(logo) {
      if (!logo) {
        this.$refs.header_logo_uploader.close();
        return;
      }

      this.$refs.header_logo_uploader.setLoading(true);
      try {
        this.insurer = await updateHeaderLogo(this.id, logo);
        this.$refs.header_logo_uploader.close();
        this.toast("Logo atualizado");
      } catch (error) {
        this.toastError("Não foi possível atualizar o logo");
      } finally {
        this.$refs.header_logo_uploader.setLoading(false);
      }
    },

    async handleUpdatePrimaryColor(form) {
      this.$refs.primary_color_picker.setLoading(true);

      try {
        this.insurer = await updateTheme(this.id, {
          primary_color: form.color,
        });
        this.$refs.primary_color_picker.close();
        this.toast("Cor atualizada");
      } catch (error) {
        this.toastError("Não foi possível alterar a cor");
      } finally {
        this.$refs.primary_color_picker.setLoading(false);
      }
    },

    async handleUpdateHeaderColor(form) {
      this.$refs.header_color_picker.setLoading(true);

      try {
        this.insurer = await updateHeader(this.id, {
          color: form.color,
          dark: form.dark,
        });
        this.$refs.header_color_picker.close();
        this.toast("Cor atualizada");
      } catch (error) {
        this.toastError("Não foi possível alterar a cor");
      } finally {
        this.$refs.header_color_picker.setLoading(false);
      }
    },
  },
};
</script>

<style lang="scss" style>
.color-rectangle {
  width: 70px;
  height: 30px;
  border: 1px solid #ccc;
  margin-right: auto;
}
</style>
