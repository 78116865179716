<template>
  <v-dialog v-model="dialog" width="350" persistent>
    <v-card :disabled="loading">
      <v-card-title>
        Atualizar logo
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="my-1">
          <v-col>
            <Logo
              :src="preview"
              :background-color="backgroundColor"
              class="mx-auto"
            ></Logo>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              label="Selecione a nova imagem"
              truncate-length="15"
              filled
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="submit()" block color="primary" :loading="loading">
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Logo from "./Logo.vue";

export default {
  mixins: [],

  components: {
    Logo,
  },

  props: {
    backgroundColor: String,
  },

  data() {
    return {
      dialog: false,
      initialSrc: null,
      loading: false,
      file: null,
      preview: "",
    };
  },

  methods: {
    open(src) {
      this.dialog = true;
      this.initialSrc = src;
      this.preview = src;
      this.file = null;
    },
    close() {
      this.dialog = false;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    submit() {
      this.$emit("submit", this.file);
    },
  },

  computed: {},

  watch: {
    file(value) {
      if (!value) {
        this.preview = this.initialSrc;
        return;
      }

      const render = new FileReader();
      render.addEventListener("load", () => {
        this.preview = render.result;
      });

      render.readAsDataURL(value);
    },
  },
};
</script>
